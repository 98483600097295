import { styled } from '@mui/system'
import Typography from '../../atoms/typography'

const Root = styled('div')``

const Title = styled(Typography)`
	margin-bottom: 30px;
	color: ${({ theme }) => theme.palette.text.secondary};
	line-height: calc(11 / 10);
	text-align: center;
	${({ theme }) => theme.breakpoints.up('md')} {
		margin-bottom: ${({ theme }) => theme.spacing(7.5)};
		margin-top: ${({ theme }) => theme.spacing(7.5)};
	}
`

export { Root, Title }
